<template>
  <div
    class="customlesson-left-small-picture1"
    :class="{
      drawingPage: isDrawingPage,
      rowMenuStyle: isRowMenu,
      verySmallMenu: smallMenu,
      smallMenu: menuSize == 'small'
    }"
  >
    <div class="small-picture-content">
      <div
        v-for="(item, index) in buttonList"
        :key="index"
        class="small-picture-content-img"
        @mouseover="mouseover(index)"
        @mouseleave="mouseleave(index)"
        @click.stop="clickHandle(item, index)"
      >
        <img
          class="canClick"
          :class="{
            isActive:
              (cilckIndex === index && !item.isPuzzlePage) ||
              (cilckIndex === null && index == 0),
            completed: numBers > item.endNumberRange && !item.isPuzzlePage,
            hover: isHover && hoverIndex === index && !item.isPuzzlePage,
            blueBorder: item.isPuzzlePage && index === 0,
            yelllowBorder:
              item.isPuzzlePage &&
              (index === 1 || numBers > item.endNumberRange),
          }"
          :src="item.image"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sceneSwitchThumbnails",
  props: ["buttons", "numBers", "isDrawingPage", "isRowMenu", "smallMenu", "menuSize"],

  data() {
    return {
      isHover: false,
      hoverIndex: null,
      cilckIndex: null,
      canClick: true,

      buttonList: this.buttons,
      numBersLocal: this.numBers,
    };
  },
  methods: {
    mouseover(index) {
      this.hoverIndex = index;
      this.isHover = true;
    },
    mouseleave(index) {
      this.hoverIndex = index;
      this.isHover = false;
    },
    clickHandle(item, index) {
      this.cilckIndex = index;
      this.$emit("changeTheNumbers", item.startNumberRange);
    },
  },
};
</script>

<style scoped lang="scss">
.customlesson-left-small-picture1 {
  z-index: 5;
  position: absolute;
  right: 0%;
  top: 0;
  width: 12%;
  background-color: #d87065;
  border-top-right-radius: 52px;
  border-bottom-left-radius: 52px;
  border-bottom: 8px solid #a53d32;
  .small-picture-content {
    margin-top: 10px;
    background-color: #cd4c3f;
    border-top-right-radius: 52px;
    border-bottom-left-radius: 46px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    .small-picture-content-img:first-of-type {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      img {
        width: 100%;
      }
    }
    .small-picture-content-img {
      margin-bottom: 5px;
      padding: 2px;
      width: 55%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      img {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    .canClick {
      box-sizing: border-box;
      border-radius: 15px;
      border: 4px solid transparent;
    }
    .isActive {
      border: 4px solid #fff;
    }
    .hover:not(.isActive) {
      box-sizing: border-box;
      border-radius: 17px;
      border: 4px solid #a53d32;
    }
    .completed {
      box-sizing: border-box;
      border-radius: 17px;
      border: 4px solid #f4d737;
    }
    // .circle {
    //   border-radius: 50%;
    // }
    .blueBorder {
      border-color: #224e96;
    }
    .yelllowBorder {
      border-color: #f4d737;
    }
  }
}
.drawingPage {
  z-index: 11;
}
.verySmallMenu {
  width: 10%;
}
.smallMenu {
  .small-picture-content-img {
  width: 40% !important;
  margin-bottom: 0 !important;

  &:first-of-type {
    margin-top:10px !important;
  }
  }
}
.rowMenuStyle {
  width: auto;

  .small-picture-content {
    display: flex;
    flex-direction: row;
  }
  .small-picture-content-img:first-of-type {
    padding-left: 30px;
  }
  .small-picture-content-img:last-of-type {
    padding-right: 30px;
  }
  .small-picture-content-img {
    margin: 8px 5px !important;
    img {
      max-width: 60px;
    }
  }
}
</style>
